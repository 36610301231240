import { artists } from "@/assets/artists";

export const images = [
  {
    largeURL: require("@/assets/img/sfw/01.png"), 
    thumbnailURL: require("@/assets/thumbnails/sfw/t01.jpg"), 
    width: 2740,
    height: 3001,
    artist: artists.paululuh,
    creationDate: "2023-03-29"
  },
  {
    largeURL: require("@/assets/img/sfw/02.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t02.jpg"),
    width: 5528,
    height: 4776,
    artist: artists.mespeth,
    creationDate: "2023-08-24"
  },
  {
    largeURL: require("@/assets/img/sfw/03.jpg"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t03.jpg"),
    width: 1863,
    height: 1941,
    artist: artists.akuva,
    creationDate: "2023-10-22"
  },
  {
    largeURL: require("@/assets/img/sfw/04.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t04.jpg"),
    width: 755,
    height: 755,
    artist: artists.mespeth,
    creationDate: "2023-10-23"
  },
  {
    largeURL: require("@/assets/img/sfw/05.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t05.jpg"),
    width: 755,
    height: 755,
    artist: artists.mespeth,
    creationDate: "2023-10-23"
  },
  {
    largeURL: require("@/assets/img/sfw/06.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t06.jpg"),
    width: 2000,
    height: 2000,
    artist: artists.gabrianne,
    creationDate: "2023-12-27"
  },
  {
    largeURL: require("@/assets/img/sfw/07.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t07.jpg"),
    width: 1996,
    height: 1803,
    artist: artists.aprion,
    creationDate: "2024-01-07"
  },
  {
    largeURL: require("@/assets/img/sfw/08.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t08.jpg"),
    width: 3600,
    height: 4000,
    artist: artists.gabrianne,
    creationDate: "2024-01-09"
  },
  {
    largeURL: require("@/assets/img/sfw/09.jpg"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t09.jpg"),
    width: 1700,
    height: 1600,
    artist: artists.gabrianne,
    creationDate: "2024-02-14"
  },
  {
    largeURL: require("@/assets/img/sfw/10.jpg"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t10.jpg"),
    width: 1600,
    height: 1900,
    artist: artists.aprion,
    creationDate: "2024-04-04"
  },
  {
    largeURL: require("@/assets/img/sfw/11.gif"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t11.gif"),
    width: 2000,
    height: 1333,
    artist: artists.graylynx,
    creationDate: "2024-06-15"
  },
  {
    largeURL: require("@/assets/img/sfw/12.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t12.jpg"),
    width: 1869,
    height: 2048,
    artist: artists.ruffusbleu,
    creationDate: "2024-08-07"
  },
  {
    largeURL: require("@/assets/img/sfw/13.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t13.jpg"),
    width: 3600,
    height: 4600,
    artist: artists.kousuracon,
    creationDate: "2024-08-20"
  },
  {
    largeURL: require("@/assets/img/sfw/14.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t14.jpg"),
    width: 3600,
    height: 4600,
    artist: artists.kousuracon,
    creationDate: "2024-08-21"
  },
  {
    largeURL: require("@/assets/img/sfw/15.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t15.jpg"),
    width: 4000,
    height: 4000,
    artist: artists.gedebuk,
    creationDate: "2024-09-04"
  },
  {
    largeURL: require("@/assets/img/sfw/16.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t16.jpg"),
    width: 4000,
    height: 4000,
    artist: artists.gedebuk,
    creationDate: "2024-09-04"
  },
  {
    largeURL: require("@/assets/img/sfw/17.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t17.jpg"),
    width: 4014,
    height: 2864,
    artist: artists.aduran,
    creationDate: "2024-09-27"
  },
  {
    largeURL: require("@/assets/img/sfw/18.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t18.jpg"),
    width: 2560,
    height: 1440,
    artist: artists.skylerie,
    creationDate: "2024-10-03"
  },
  {
    largeURL: require("@/assets/img/sfw/19.png"),
    thumbnailURL: require("@/assets/thumbnails/sfw/t19.jpg"),
    width: 1248,
    height: 1491,
    artist: artists.skylerie,
    creationDate: "2024-10-09"
  }
];